import React, {useContext, useEffect, useState} from "react";
import ActsApi from "../../services/actsApi";
import AuthAPI from "../../services/authAPI";
import './style.scss';
import List from "./List";
import AuthContext from "../../contexts/AuthContext";

const Index = ({history}) => {
    const {setIsAuthenticated} = useContext(AuthContext);

    const [motif, setMotif] = useState(1);
    const [acts, setActs] = useState([]);
    const [type, setType] = useState('CCAM NGAP IVG aucun');

    const [search, setSearch] = useState('');

    const handleChange = (setterMethod, event) => {
        setterMethod(event.target.value);
    };

    // Permet de récupérer les acts d'un motif
    const fetchActs = async () => {
        try {
            const data = await ActsApi.findAll(motif);
            setActs(data);
        } catch ({response}) {
            if (response.status === 401) {
                AuthAPI.refreshToken()
                    .then(() => fetchActs())
                    .catch(() => {
                        AuthAPI.logout();
                        setIsAuthenticated(false)
                    });
            }
            console.log(response);
        }
    };

    useEffect(() => {
        fetchActs();
    }, [motif]);

    const filteredActs = acts.filter(act =>
        type.includes(act.type) &&
        (act.name.toLowerCase().includes(search.toLowerCase()) ||
            act.keyWords.join(' ').toLowerCase().includes(search.toLowerCase())
        )
    );

    return (
        <div className="content-body">
            <div className="container">
                <h1 className="title is-2">Liste des Actes </h1>

                <List
                        history={history}
                        handleChange={handleChange}
                        motif={motif}
                        setMotif={setMotif}
                        setType={setType}
                        search={search}
                        setSearch={setSearch}
                        filteredActs={filteredActs}
                        type={"acts"}
                        handleActClick={() => {}} // not needed for acts listing
                    />
            </div>
        </div>
    )
};

export default Index;
