import Majoration from "../majoration";



/**
 * build array of selected acts with prices according to User
 * @param selectedActs
 * @param aUser
 * @returns {*}
 */
function getSelectedActsPrices(selectedActs, aUser) {
    return selectedActs.map((act) => {
        if (!aUser.metropole) {
            if (act.domtomPrice > 0) {
                return act.domtomPrice;
            }
        }
        return act.metropolePrice;
    });
}

/**
 * build an array of selected acts of prices with ratio applied
 * @param selectedActs
 * @param user
 * @returns {*[]}
 */
function getActsRatio(selectedActs, user) {

    const selectActsRawPrices = getSelectedActsPrices(selectedActs, user);
    if (selectActsRawPrices.length < 2) return selectActsRawPrices;

    //we got 2 acts : check no one is a ratio act
    if (Majoration.ACTS_MAJORATION.includes(selectedActs[0].code) || Majoration.ACTS_MAJORATION.includes(selectedActs[1].code)) {
        return selectActsRawPrices;
    }

    let actsCalculatedPrices = [...selectActsRawPrices];

    // if rule id is 1, 2, or 4 with rule of first selected Act is applied
    if (selectedActs[0].rule && (selectedActs[0].rule.id === 1 || selectedActs[0].rule.id === 2 || selectedActs[0].rule.id === 4)) {
        // acts 1 : 100%, act 2 : 50%
        const minPrice = Math.min(...selectActsRawPrices);
        actsCalculatedPrices[actsCalculatedPrices.indexOf(minPrice)] = 0.5 * minPrice;
    }

    return actsCalculatedPrices;
}


export default {
    getSelectedActsPrices,
    getActsRatio,
}
