import React from 'react';

const Field = ({name, label, value, onChange, placeholder = '', type = 'text', error = '', icon = false, readOnly = false, required = false}) => {
    return (
        <div className="field">
            <label className="label" htmlFor={name}>{label}</label>
            <div className="control has-icons-left">
                <input
                    required={required}
                    readOnly={readOnly}
                    name={name}
                    value={value}
                    placeholder={placeholder || label}
                    type={type}
                    onChange={onChange}
                    className={"input" + (error && " is-danger")}
                />
                {icon && <span className="icon is-small is-left"><i className={"fas fa-" + icon}/></span>}
            </div>
            {error && <p className="help is-danger">{error}</p>}
        </div>
    );
};

export default Field;
