import React from 'react';

export default React.createContext({
  isAuthenticated: false,
  setIsAuthenticated: (value) => {},
  user: {
    firstName: '',
    lastName: '',
    metropole: false,
    id: 0
  },
  setUser: (value) => {}
});
