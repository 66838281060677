import axios from 'axios';
import ActsApi from './actsApi';

const ALL_MOTIFS = [
    {
        slug: 'hors-grossesse',
        value: 'Hors Grossesse'
    },
    {
        slug: 'gynecologie',
        value: 'Gynécologie'
    },
    {
        slug: 'grossesse',
        value: 'Grossesse'
    },
    {
        slug: 'accouchement',
        value: 'Accouchement'
    },
    {
        slug: 'post-natal',
        value: 'Suivi postnatal'
    },
    {
        slug: 'echographie',
        value: 'Echographie'
    }
];


function findAll() {
    return axios.get(process.env.API_ENTRYPOINT + '/motifs').then(response => response.data['hydra:member']);
}


/**
 * find one motif to get his name and number of acts
 * @param motifSlug
 * @returns {Promise<AxiosResponse<T>>}
 */
function findMotif(motifSlug) {
    const motifId = ActsApi.MOTIF_SLUGS.get(motifSlug);
    return axios.get(process.env.API_ENTRYPOINT + '/motifs/' + motifId);
}

export default {
    findMotif,
    findAll,
    ALL_MOTIFS,
};
