import React from 'react';

const Select = ({name, label, value, error = '', icon = false, onChange, children, large}) => {
    return (
        <div className="field">
            <label className="label" htmlFor={name}>{label}</label>
            <div className={"control" + (icon && " has-icons-left")}>
                <div className={"select is-info " + (large && large)}>
                    <select
                        onChange={onChange}
                        name={name}
                        id={name}
                        value={value}
                        className={'form-control ' + (error && 'is-invalid')}
                    >
                        {children}
                    </select>
                </div>
                {icon && <span className="icon is-small is-left"><i className={"fas fa-" + icon}></i></span>}
            </div>
            <p className="invalid-feedback">{error}</p>
        </div>
    );
};

export default Select;
