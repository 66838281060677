import React, {useContext, useEffect, useState} from 'react';
import Pagination from '../../components/Pagination';
import AuthAPI from '../../services/authAPI';
import NewsApi from '../../services/newsApi';
import AuthContext from "../../contexts/AuthContext";
import logo from '../../images/logoURPS.jpg';

const NewsList = () => {
    const {setIsAuthenticated} = useContext(AuthContext);
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    // Permet d'aller récupérer les news
    const fetchNews = async () => {
        try {
            const data = await NewsApi.findAll();
            setNews(data);
            setIsLoading(false);
        } catch ({response}) {
            if (response.status === 401) {
                AuthAPI.refreshToken()
                    .then(() => fetchNews())
                    .catch(() => {
                        AuthAPI.logout();
                        setIsAuthenticated(false)
                    });
            }
            setIsLoading(false);
        }
    };

    // Au chargement du composant, on va chercher les news
    useEffect(() => {
        fetchNews();
    }, []);


    // pagination des donnees
    const itemsPerPage = 12;
    const handleChangePage = page => {
        setCurrentPage(page);
    };


    //pagination des données
    const paginatedNews = Pagination.getData(news, currentPage, itemsPerPage);

    const renderNewsList = () => {
        if (news.length > 0) {
            return (
                <>
                    <div>
                        {paginatedNews.map(news =>
                            <div className="box" key={news.id}>
                                <h2 className="title">{news.title}</h2>
                                <div className="content" dangerouslySetInnerHTML={{__html: news.content}}/>
                            </div>
                        )}
                    </div>
                    {itemsPerPage < news.length &&
                    <Pagination currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                length={news.length}
                                onPageChange={handleChangePage}
                    />}
                </>
            )
        } else {
            return (
                <div className="blocks-box">
                    <img src={logo} alt="logo"/>
                </div>
            )
        }
    };

    return (
        <div className="content-body">
            <div className="container">
                <>
                    <div style={{marginBottom: 30}}>
                        <h1 className="title is-size-2-desktop is-size-3-touch">Bienvenue sur l'application de
                            l'URPS</h1>
                        {news.length > 0 &&
                        <h2 className="title is-4 shadow is-success is-inline">Dernières actualités</h2>}
                    </div>

                    {isLoading &&
                    <div className="spinner-block">
                        <p className="has-text-centered">Chargement</p>
                        <progress className="progress is-small is-primary" max="100">15%</progress>
                    </div>
                    }
                    {renderNewsList()}
                </>
            </div>
        </div>
    );
};

export default NewsList;
