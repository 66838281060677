import React from 'react';
import './home.css';
import {NavLink} from "react-router-dom";

const HomePage = () => {
    return (
        <div className="hero-body">

            <div className="container is-fluid">
                <div className="columns">
                    <div className="column is-two-third">
                        <div className="content hello">
                            <p className="title is-4">
                                Découvrez <span className="shadow is-success">la nouvelle application </span>
                                proposée par l'URPS <br/>
                            </p>
                            <NavLink to="/register" className="button is-success is-rounded">
                                Inscription
                            </NavLink>
                        </div>
                    </div>
                    <div className="column">
                        <div className="content">
                            <p>Vous pourrez ici :</p>
                            <ul>
                                <li>Consulter la liste des actes</li>
                                <li>Calculer la date de grossesse</li>
                                <li>Calculer le prix de la consultation</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default HomePage;
