/**
 * handles acts type filter (CCAM / NGAP) according to the rule of 1s selected act
 * @param act
 * @param selectedActs
 * @returns {boolean}
 */
function handleRulesTypes(act, selectedActs) {
    if (selectedActs.length && selectedActs[0].rule) {
        const ruleId = selectedActs[0].rule.id;
        switch (ruleId) {
            // if CCAM selected => only show NGAP
            case 4:
                if (selectedActs[0].type === "CCAM") {
                    return act.type === "NGAP"
                }
                return true;
            // if NGAP selected => only show CCAM
            case 2:
                if (selectedActs[0].type === "NGAP") {
                    return act.type === "CCAM"
                }
                return true;
            // Règle 3 : on ne peut sélectionner que des actes NGAP
            case 3:
                if (selectedActs[0].type === "NGAP") {
                    return act.type === "NGAP"
                }
                return true;
            default:
                return true
        }
    }
    return true;
}

/**
 * handles acts exclusion filter (only show some acts) depending of rule of 1st selected act
 * @param act
 * @param selectedActs
 * @returns {boolean}
 */
function handleRulesFilter(act, selectedActs) {
    // quand consultation permettre de cocher seulement frottis et échographie de grossesse T1, T2, T3 & majoration MSG
    const ACTS_CONSULTATION = ["JQHD001", "JQQM010", "JQQM015", "JQQM018", "JQQM019", "JQQM016", "JQQM017", "MSF"];

    const selectedActsTypes = selectedActs.map((act) => act.type);

    if (selectedActs.length && selectedActs[0].rule) {
        const ruleId = +selectedActs[0].rule.id;
        // exclude all acts not in ACTS_CONSULTATION
        if (ruleId === 1) {
            return ACTS_CONSULTATION.includes(act.code);
        } else if (!ACTS_CONSULTATION.includes(selectedActs[0].code)) {
            // if not consultation selected  and not act in ACTS_CONSULTATION: remove consultation
            return act.code !== "C"
        }
    }
    return true;
}

export default {
    handleRulesFilter,
    handleRulesTypes,
}
