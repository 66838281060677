import React from 'react';
import {Link} from 'react-router-dom';

const NotFound = () => {

    return (
        <div className="hero-body">
            <div className="container">

                <article className="message is-warning">
                    <div className="message-header">
                        <h3 className="title">Ooops ..</h3>
                    </div>
                    <div className="message-body">
                        .. pour revenir à l'accueil
                        &nbsp;<Link to="/#" className="alert-link">
                        cliquez ici
                    </Link>
                    </div>
                </article>
            </div>
        </div>
    );
};

export default NotFound;
