import axios from 'axios';

function findAll() {
    const hasFilter = '?publishDate[before]=today&endDate[after]=today';
    return axios.get(process.env.REACT_APP_API_ENTRYPOINT + '/news' + hasFilter)
        .then(response => response.data['hydra:member']);
}


export default {
    findAll,
};
