import React from 'react';

const NoResult = ({isFeminin = false, name}) => {
    return (
        <article className="message is-primary has-text-centered">
            <div className="message-body">
                <strong>Aucun{isFeminin && "e"} {name}</strong> ne correspond à votre recherche ..
            </div>
        </article>
    );
};

export default NoResult;
