import axios from 'axios';

const MOTIF_SLUGS = new Map([
    ['hors-grossesse', 1],
    ['echographie', 2],
    ['post-natal', 3],
    ['grossesse', 4],
    ['accouchement', 5],
]);


/**
 * find all Acts from one motif
 * @param motifId
 * @returns {Promise<AxiosResponse<T> | never>}
 */
function findAll(motifId) {
    return axios.get(`${process.env.REACT_APP_API_ENTRYPOINT}/motifs/${motifId}/acts`)
        .then(response => response.data['hydra:member']);
}

export default {
    findAll,
    MOTIF_SLUGS,
};
