import axios from 'axios';
import jwtDecode from 'jwt-decode';

/**
 * suppression du token dans le localStorage + suppression du user
 */
function logout() {
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('user');
    delete axios.defaults.headers['Authorization'];
}

/**
 * authenticate in LoginPage
 * @param credentials
 * @param setUser
 * @returns {Promise<void>}
 */
async function authenticate(credentials, setUser) {
    let res = await axios.post(process.env.REACT_APP_API_ENTRYPOINT + '/login/user', credentials);
    // add token in browser storage
    let {token} = res.data;
    window.localStorage.setItem('authToken', token);
    setAxiosHeader(token);

    //add refresh token in browser storage
    let {refresh_token} = res.data;
    window.localStorage.setItem('refreshToken', refresh_token);

    // add user info in browser storage
    let {user} = jwtDecode(token);
    delete user.role;
    setUser(user);
    window.localStorage.setItem('user', JSON.stringify(user));
}

/**
 * try to refresh authToken with refreshToken from local storage
 * @returns {Promise<CancelToken | o | never>}
 */
async function refreshToken() {
    delete axios.defaults.headers['Authorization'];

    // 1) get refreshToken
    const refreshToken = window.localStorage.getItem('refreshToken');

    // 2) send request to /token/refresh => get token
    const json_refresh_token = {
        'refresh_token': refreshToken
    };

    const res = await axios.post(process.env.REACT_APP_API_ENTRYPOINT + '/token/refresh', json_refresh_token);
    // 3) set new token to axios
    window.localStorage.setItem('authToken', res.data.token);
    setAxiosHeader(res.data.token);
    return true;
}

function setAxiosHeader(token) {
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
}


/**
 * called at the app start to check if user is authenticated
 * @returns {Promise<boolean>}
 */
async function setupAxios() {
    const token = window.localStorage.getItem('authToken');
    if (token) {
        const {exp: expirationDate} = jwtDecode(token);
        if (expirationDate * 1000 > new Date().getTime()) {
            setAxiosHeader(token);
            return true;
        } else {
            try {
                await refreshToken();
                return true;
            } catch (e) {
                console.error(e);
                logout();
                return false;
            }
        }
    } else {
        logout();
        return false;
    }
}


/**
 * check if user is authenticated
 * @returns {boolean}
 */
function isAuthenticated() {
    // 1. Voir si on a un token ?
    const token = window.localStorage.getItem("authToken");
    // 2. Si le token est encore valide
    if (token) {
        const {exp: expiration} = jwtDecode(token);
        return (expiration * 1000 > new Date().getTime())
    }
    return false;
}

function getUserFromStorage() {
    const retrievedUser = window.localStorage.getItem('user');
    return JSON.parse(retrievedUser);
}


export default {
    authenticate,
    refreshToken,
    logout,
    setupAxios,
    isAuthenticated,
    getUserFromStorage
};
