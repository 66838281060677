import React from "react";
import './Footer.scss';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className="hero-foot has-background-light">
            <div className="block-footer">
                <nav className="breadcrumb has-bullet-separator is-centered" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <Link to="/legals" className="footer-link">
                                Conditions générales
                            </Link>
                        </li>
                        <li>
                            <span className="footer-link">&nbsp;© 2019 URPS Grand-Est</span>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
};

export default Footer;
