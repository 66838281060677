import Majoration from "../majoration";

function showNGAPMajoration(selectedActs) {
    const selectedActsTypes = selectedActs.map((act) => act.type);
    return selectedActsTypes.includes("NGAP");
}


/**
 * returns price for the transport according to the user, location and means of transport
 * @param mileage
 * @param user
 * @returns {number|*}
 */
function getTransportRatio(mileage, user) {
    const selectedMileage = +mileage.typeKey;
    const selectedTransport = Majoration.TRANSPORTS[mileage.typeKey];
    let distanceRatio = 0;
    if (selectedMileage === 4) {
        return 0;
    }
    if (selectedMileage < 3) {
        distanceRatio += 2 * mileage.distance * (user.metropole ? selectedTransport.metropolePrice : selectedTransport.domtomPrice);
    }
    return distanceRatio + (user.metropole ? Majoration.TRANSPORTS[3].metropolePrice : Majoration.TRANSPORTS[3].domtomPrice);
}


/**
 * return 1 Array of the increase type selected + sum of this icrease
 * @param increase array of all increase type , with this format [1, -1, -1, 1]
 * @param selectedType
 * @returns {*[]}
 */
function getIncreaseType(increase, selectedType) {
    const selectedRatio = (selectedType === "CCAM" ? Majoration.CCAM_RATIO : Majoration.NGAP_RATIO);

    // build an array with the selected ratio Id
    const ratioSelected = increase.map((isSelected, key) => {
        if (isSelected) {
            return key;
        }
        return -1;
    }).filter((ratio) => ratio !== -1);

    // sum ratio prices from selectedRatio id
    // we execute our reducer starting at index 0
    const increasSum = ratioSelected.reduce((sum, ratioKey) => {
            return sum + selectedRatio[ratioKey].price
        }
        , 0);
    return [ratioSelected, increasSum]
}


export default {
    showNGAPMajoration,
    getTransportRatio,
    getIncreaseType,
}
