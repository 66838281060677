import React, {useEffect} from 'react';
import HomePage from './HomePage';
import NewsList from './NewsList';

const Index = ({isLoading, isAuthenticated}) => {

    // useEffect(() => {
    //     console.log("Index component : isLoading", isLoading);
    // }, [isLoading, isAuthenticated])


    if (isLoading) {
        return (
            <p>Loading ...</p>
        );
    } else {
        if (isAuthenticated === true) {
            return <NewsList/>
        } else {
            return <HomePage/>
        }
    }
};

export default Index
