import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';


const UserRoute = ({path, component}) => {
    const {isAuthenticated} = useContext(AuthContext);

    if (isAuthenticated === undefined) {
        return <p>
            Chargement ...
        </p>
    }
    return isAuthenticated ?
        <Route path={path} component={component}/> :
        <Redirect to="/login"/>;
};

export {
    UserRoute
};
