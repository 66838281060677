function getFirst10Words(str) {
    const arrayWord = str.split(/\s+/);
    const wordSplitted = arrayWord.slice(0, 10).join(" ");

    return arrayWord.length < 10 ? wordSplitted : (wordSplitted + " ...");
}

function turnToDecimals(number) {
    const with2Decimals = number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return parseFloat(with2Decimals);
}

export default {
    getFirst10Words,
    turnToDecimals,
}
