import React, {useEffect, useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Result from "./Result";
import './Periods.css';

registerLocale("fr", fr);

const Periods = () => {

    const [startDate, setStartDate] = useState(new Date());
    const [withPeriods, setWithPeriods] = useState(true);

    const handleSelectChange = () => {
        const newWithIncrease = !withPeriods;
        setWithPeriods(newWithIncrease)
    };

    return (
        <div className="content-body">

            <div className="container has-text-centered">
                <h1 className="title is-2">Chosissez une date</h1>
                <div className="title--block">
                    <h2 className="subtitle is-4">Calcul depuis &nbsp;&nbsp;</h2>
                    <div className="select is-success">
                        <select onChange={handleSelectChange}>
                            <option>début de grossesse</option>
                            <option>dernières règles</option>
                        </select>
                    </div>
                </div>
                <DatePicker
                    locale="fr"
                    dateFormat="d MMMM yyyy"
                    todayButton="Ajourd'hui"
                    inline
                    selected={startDate}
                    onChange={(date) => {setStartDate(date)}}
                />
                <br/>
                <br/>
                <h3 className="title is-5">Résultat (terme prévu) :</h3>
                <Result
                    date={startDate}
                    withPeriods={withPeriods}
                />
            </div>
        </div>
    );
};

export default Periods;
