import React, {useState} from 'react';
import Majoration from './majoration';

import TransportService from "./services/transport";

const Transport = ({setStep, step, selectedActs, mileage, setMileage, reset, increase, setIncrease}) => {

    const [transportOptionsVisible, setOptionsVisible] = useState(false);

    const handleChange = ({target}) => {
        const name = target.name;
        setMileage({
            ...mileage,
            [name]: target.value
        });
    };

    const handleChangeDistance = ({target}) => {
        setMileage({
            ...mileage,
            distance: target.value.replace(/,/g, '.')
        });
    };

    const handleIncreaseType = (ratioKey) => {
        // add or remove this increase
        setIncrease(increase.map((ratio, key) => {
            if (key === ratioKey) {
                return !ratio;
            }
            return ratio;
        }))
    };

    const isValid = () => {
        if (mileage.typeKey < 3) {
            return mileage.distance > 0
        }
        return true
    };

    const showTransportOptions = () => {
        setOptionsVisible(true);
        setMileage({
            ...mileage,
            typeKey: 0
        });
    };

    const hideTransportOptions = () => {
        setOptionsVisible(false);
        setMileage({
            ...mileage,
            typeKey: 4
        });
    };

    const isNGAPMajoration = TransportService.showNGAPMajoration(selectedActs);
    const isRule3_NoMajoration = selectedActs[0].rule.id === 3 || selectedActs[0].rule.id === 5;

    return (
        <div className="content-body">
            <div className="container">

                <div className="block-title is-flex-between">
                    <div className="has-mobile-margin-bottom">
                        <h2 className="title is-3 is-inline">Choix du Transport</h2>
                    </div>
                    <div>
                        <button className="button is-outlined is-rounded is-primary"
                                onClick={reset}
                        >
                            Réinitialiser
                        </button>
                    </div>
                </div>

                <div className="box">

                        <div className="block-title is-flex-around">
                            <label>
                                <input
                                    checked={!transportOptionsVisible}
                                    name="typeKey"
                                    type="radio"
                                    onChange={() => hideTransportOptions()}/>&nbsp;
                                Consultation au cabinet
                            </label>
                            <label>
                                <input
                                    id="showTransport"
                                    checked={transportOptionsVisible}
                                    type="radio"
                                    onChange={() => showTransportOptions()}/>&nbsp;
                                Consultation à domicile
                            </label>
                        </div>

                    <div className="transport-block">
                        <div className="control has-mobile-margin">
                            {transportOptionsVisible && Majoration.TRANSPORTS_OPTIONS.map((transport, key) =>
                                <div key={key}>
                                    <label className="radio">
                                        <input
                                            checked={+mileage.typeKey === key}
                                            name="typeKey"
                                            value={key}
                                            type="radio"
                                            onChange={handleChange}/>&nbsp;
                                        {transport.type} {transport.code && <span>({transport.code})</span>}
                                    </label>
                                    <br/>
                                </div>
                            )}
                        </div>

                        {transportOptionsVisible && <div className="field">
                            <div className="control">
                                <label htmlFor="distance">Distance à votre cabinet :</label>
                                <input
                                    disabled={mileage.typeKey >= 3}
                                    onChange={handleChangeDistance}
                                    value={mileage.distance}
                                    id="distance"
                                    name="distance"
                                    className="input is-success"
                                    type="numeric"
                                    placeholder="distance en kilomètre"
                                />
                            </div>
                        </div>}

                    </div>
                </div>

                {!isRule3_NoMajoration &&
                <>
                    <div className="block-title has-mobile-margin-bottom">
                        <h2 className="title is-3 is-inline">Majoration ({isNGAPMajoration ? "NGAP" : "CCAM"})</h2>
                    </div>
                    <div className="increase-bloc box">
                        <div className="control">
                            {(isNGAPMajoration ? Majoration.NGAP_RATIO : Majoration.CCAM_RATIO).map((ratio, key) =>
                                <div key={key}>
                                    <label className="checkbox">
                                        <input
                                            checked={increase[key]}
                                            onChange={() => handleIncreaseType(key)}
                                            type="checkbox"
                                        />&nbsp;
                                        {ratio.type} ({ratio.code})
                                    </label>
                                    <br/>
                                </div>
                            )}
                        </div>
                    </div>
                </>
                }


                <div className="tabs is-centered">
                    <ul className="block-nav">
                        <li>
                            <button onClick={() => {setStep(step - 1)}}
                                    className="button is-rounded is-warning">
                                <span className="icon"><i className="fas fa-angle-left" aria-hidden="true"/></span>
                                <span>Ajouter un acte</span>
                            </button>
                        </li>
                        <li className={(isValid() ? "" : "is-not-visible")}>
                            <button onClick={() => setStep(step + 1)}
                                    className="button is-rounded is-warning">
                                <span>Suivant</span>
                                <span className="icon"><i className="fas fa-angle-right" aria-hidden="true"/></span>
                            </button>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    );
};

export default Transport;
