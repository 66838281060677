import axios from "axios";

/**
 * find one user
 * @returns {Promise<AxiosResponse<T>>}
 */
function findCurrentUser(userId) {
    return axios.get(process.env.REACT_APP_API_ENTRYPOINT + '/users/' + userId);
}

export default {
    findCurrentUser
};
