import React, {useContext, useEffect, useState} from 'react';
import ActsApi from "../../services/actsApi";
import AuthAPI from "../../services/authAPI";
import Majoration from './majoration';
import AuthContext from "../../contexts/AuthContext";

import ActsFilter from "./services/actsFilter";
import TransportService from "./services/transport";
import Helper from "./services/helper";

import {toast} from "react-toastify";
import List from "../acts/List";

const ChoiceList = ({step, setStep, selectedActs, setSelectedActs, setIncrease}) => {
    const {setIsAuthenticated} = useContext(AuthContext);

    const [motif, setMotif] = useState(1);
    const [acts, setActs] = useState([]);

    const [search, setSearch] = useState('');
    const [type, setType] = useState('CCAM NGAP IVG aucun');

    const handleChange = (setterMethod, event) => {
        setterMethod(event.target.value);
    };

    // Permet de récupérer les acts d'un motif
    const fetchActs = async () => {
        try {
            const data = await ActsApi.findAll(motif);
            setActs(data);
        } catch ({response}) {
            if (response.status === 401) {
                AuthAPI.refreshToken()
                    .then(() => fetchActs())
                    .catch(() => {
                        AuthAPI.logout();
                        setIsAuthenticated(false)
                    });
            }
        }
    };

    // Au chargement du composant, on va chercher les acts
    useEffect(() => {
        fetchActs();
    }, [motif]);

    const selectedActsIndex = selectedActs.map((act) => act.id);

    const filteredActs = acts.filter(act =>
        // remove selected acts
        !selectedActsIndex.includes(act.id) &&

        // apply type filter from user
        type.includes(act.type) &&

        // handle types filter
        ActsFilter.handleRulesTypes(act, selectedActs) &&

        // handle rule filter from 1st selected act
        ActsFilter.handleRulesFilter(act, selectedActs) &&

        // filter from user input
        (act.name.toLowerCase().includes(search.toLowerCase()) ||
            act.keyWords.join(' ').toLowerCase().includes(search.toLowerCase())
        )

        // max 2 Acts selected
        && (selectedActs.length <= 1)
    );

    const addAct = actId => {
        const newActArray = acts.filter((act) => act.id === actId);
        setSelectedActs([...selectedActs, newActArray[0]]);
        toast.info(` Acte ajouté`, {autoClose: 2000});
    };

    const removeAct = (actKeyToRemove) => {
        setSelectedActs(selectedActs.filter((act, key) => key !== actKeyToRemove));
        toast.info(` Acte retiré`, {autoClose: 2000});
    };

    useEffect(() => {
        if (selectedActs.length) {
            const isNGAP = TransportService.showNGAPMajoration(selectedActs);
            // we build an array of the size needed for ratio choices
            setIncrease(new Array(isNGAP ? Majoration.NGAP_RATIO.length : Majoration.CCAM_RATIO.length).fill(0));
        }
    }, [selectedActs]);

    return (
        <div className="content-body">
            <div className="container">

                <div className="block-title">
                    <h1 className="title is-size-2-desktop is-inline">Choix des actes</h1>
                </div>

                <article className="message is-warning">
                    <div className="message-body">
                        <h3 className="title is-5">{selectedActs.length
                            ? (selectedActs.length === 1 ? "Acte sélectionné" : ("Actes sélectionnés (" + selectedActs.length + ")"))
                            : "Veuillez sélectionner un ou des actes"
                        } </h3>
                        <h4 className="subtitle is-6">
                            {selectedActs.length && selectedActs[0].rule ? " (Règle : " + selectedActs[0].rule.name + ")" : ""}
                        </h4>

                        {selectedActs.length > 0 &&
                        <ol>
                            {selectedActs.map((act, key) =>
                                <li key={key}>
                                    {Helper.getFirst10Words(act.name)} ({act.code}) ~ <strong>{act.type}</strong>&nbsp;
                                    <button className="delete" onClick={() => removeAct(key)}></button>
                                </li>
                            )}
                        </ol>}
                    </div>

                </article>

                {selectedActs.length > 0 &&
                <div className="has-text-right step-block">
                    <button onClick={() => {setStep(step + 1)}}
                            className="button is-rounded is-warning">
                        <span>Etape Suivante</span>
                        <span className="icon"><i className="fas fa-angle-right" aria-hidden="true"/></span>
                    </button>
                </div>
                }

                <List
                    handleChange={handleChange}
                    motif={motif}
                    setMotif={setMotif}
                    setType={setType}
                    search={search}
                    setSearch={setSearch}
                    filteredActs={filteredActs}
                    handleActClick={addAct}
                    type={"consultation"}
                />

            </div>
        </div>
    );
};

export default ChoiceList;
