import React from "react";
import moment from "moment/moment";
import 'moment/locale/fr'


const Result = ({date, withPeriods}) => {

    const formatDate = date => {
        return moment(date)
            .add(withPeriods ? 41 : 39, "weeks")
            .format("dddd Do MMMM YYYY")
    };

    return (
        <button className="result-btn">
            {formatDate(date)}
        </button>
    );
};

export default Result;
