import React from 'react';

const Legals = () => {
    return (
        <div className="content-body">
            <div className="container">

               <h1 className="title is-2">Condition générales </h1>
                <p>A venir ..</p>
            </div>
        </div>
    );
};

export default Legals;
