import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import AuthAPI from "../../services/authAPI";
import AuthContext from "../../contexts/AuthContext";

const Act = ({history, match}) => {
    const {setIsAuthenticated} = useContext(AuthContext);
    const [act, setAct] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const {id} = match.params;

    const fetchAct = async () => {
        try {
            const {data} = await axios.get(process.env.REACT_APP_API_ENTRYPOINT + '/acts/' + id);
            setAct(data);
            console.log(data);
            setIsLoading(false);
        } catch ({response}) {
            if (response.status === 401) {
                AuthAPI.refreshToken()
                    .then(() => fetchAct())
                    .catch(() => {
                        AuthAPI.logout();
                        setIsAuthenticated(false)
                    });
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAct()
    }, []);


    if (isLoading || !act.name) {
        return (
            <div className="spinner-block">
                <p className="has-text-centered">Chargement</p>
                <progress className="progress is-small is-primary" max="100">15%</progress>
            </div>
        )
    }
    return (
        <div className="content-body">
            <div className="container">
                <div className="is-flex-between block-title">
                    <div className="has-mobile-margin-bottom">
                        <h1 className="title is-2">Acte : </h1>
                        <h2 className="subtitle is-4">{act.name}</h2>
                    </div>
                    <button className="button is-outlined is-primary" onClick={() => history.push('/actes')}>
                        <i className="fas fa-arrow-left"/>&nbsp;Retour aux actes
                    </button>
                </div>

                <div className="block-tags">
                    <div className="tags has-addons are-medium">
                        <span className="tag">Motif</span>
                        <span className="tag is-info">{act.motif.name}</span>
                    </div>
                    <div className="tags has-addons are-medium">
                        <span className="tag">Type</span>
                        <span className="tag is-info">{act.type}</span>
                    </div>
                    <div className="tags has-addons are-medium">
                        <span className="tag">Code</span>
                        <span className="tag is-info">{act.code}</span>
                    </div>
                </div>


                <div className="box">
                    <div>
                        <nav className="level">
                            {(act.factor > 0) &&
                            <div className="level-item has-text-centered">
                                <div>
                                    <p className="heading">Coefficient</p>
                                    <p className="title">{act.factor}</p>
                                </div>
                            </div>
                            }
                            <div className="level-item has-text-centered">
                                <div>
                                    <p className="heading">Prix métropole</p>
                                    <p className="title">{act.metropolePrice} €</p>
                                </div>
                            </div>
                            {(act.domtomPrice > 0) &&
                            <div className="level-item has-text-centered">
                                <div>
                                    <p className="heading">Prix DOMTOM</p>
                                    <p className="title">{act.domtomPrice} €</p>
                                </div>
                            </div>
                            }
                        </nav>
                    </div>
                </div>

                <div className="box block-rules">
                    {act.message &&
                    <div>
                        <h3 className="title is-4 has-text-centered">Message</h3>
                        <p>{act.message}</p>
                    </div>
                    }
                    <div>
                        <h3 className="title is-4 has-text-centered">Règles</h3>
                        <p>{act.rule.name}</p>
                    </div>
                </div>

                <div className="box has-text-centered">
                    <h3 className="title is-4 ">Mots Clés</h3>
                    <div className="is-flex-center">
                        {act.keyWords.map((word, key) =>
                            <span key={key} className="tag is-primary is-rounded is-medium">{word}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Act;
