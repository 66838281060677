import React, {useEffect, useState, useContext} from 'react';
import Pagination from '../../components/Pagination';
import AuthAPI from '../../services/authAPI';
import FaqApi from '../../services/faqApi';
import AuthContext from "../../contexts/AuthContext";
import NoResult from "../../components/NoResult";

const FaqList = () => {
    const {setIsAuthenticated} = useContext(AuthContext);

    const [faqs, setFaqs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    // Permet d'aller récupérer les faqs
    const fetchFaqs = async () => {
        try {
            const data = await FaqApi.findAll();
            setFaqs(data);
            setIsLoading(false);
        } catch ({response}) {
            if (response.status === 401) {
                AuthAPI.refreshToken()
                    .then(() => fetchFaqs())
                    .catch(() => {
                        AuthAPI.logout();
                        setIsAuthenticated(false)
                    });
            }
            setIsLoading(false);
        }
    };

    // Au chargement du composant, on va chercher les faqs
    useEffect(() => {
        fetchFaqs();
    }, []);


    // pagination des donnees
    const itemsPerPage = 5;
    const handleChangePage = page => {
        setCurrentPage(page);
    };


    const handleSearch = event => {
        const value = event.target.value;
        setSearch(value);
        setCurrentPage(1);
    };
    // handle search input with content, title & company
    const filteredFaqs = faqs.filter(faq =>
        faq.question.toLowerCase().includes(search.toLowerCase()) ||
        faq.answer.toLowerCase().includes(search.toLowerCase())
    );

    //pagination des données
    const paginatedFaqs = Pagination.getData(filteredFaqs, currentPage, itemsPerPage);

    const renderFaqsList = () => {
        if (paginatedFaqs.length > 0) {
            return (
                <>
                    <div className="">
                        {paginatedFaqs.map(faq =>
                            <div className="box" key={faq.id}>
                                <h2 className="title">{faq.question}</h2>
                                <div className="content" dangerouslySetInnerHTML={{__html: faq.answer}}/>
                            </div>
                        )}
                    </div>

                    {itemsPerPage < filteredFaqs.length &&
                    <Pagination currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                length={filteredFaqs.length}
                                onPageChange={handleChangePage}
                    />}
                </>
            )
        } else if(search.length > 0) {
            return (
                <NoResult
                    isFeminin={true}
                    name="FAQ"
                />
            )
        }
    };

    if (isLoading) {
        return (
            <div className="spinner-block">
                <p className="has-text-centered">Chargement</p>
                <progress className="progress is-small is-primary" max="100">15%</progress>
            </div>
        )
    }
    return (
        <div className="content-body">
            <div className="container">
                <>
                    <div style={{marginBottom: 10}} className="has-text-centered-mobile">
                        <h1 className="title is-size-2-desktop">Foire aux Questions</h1>
                    </div>

                    {faqs.length > 0 && <div className="field" style={{marginBottom: 30}}>
                        <div className="control">
                            <input className="input is-rounded is-primary"
                                   value={search}
                                   onChange={handleSearch}
                                   type="text"
                                   placeholder="Rechercher ..."/>
                        </div>
                    </div>}

                    {(faqs.length === 0 && "Il n'y a aucune FAQ disponible") || renderFaqsList()}
                </>
            </div>
        </div>
    );
};

export default FaqList;
