import React, {useContext} from 'react';
import Majoration from './majoration';
import AuthContext from "../../contexts/AuthContext";

import Helper from "./services/helper";
import Transport from "./services/transport";
import ActsRatio from "./services/actsRatio";

const Result = ({setStep, step, selectedActs, mileage, reset, increase}) => {
        //needed to get user location (metropole vs domtom)
        const {user} = useContext(AuthContext);

        // ne need prices before and after ratio : we show both to User
        const selectedActsPrices = ActsRatio.getSelectedActsPrices(selectedActs, user);
        const selectedActsRatio = ActsRatio.getActsRatio(selectedActs, user);

        // acts
        const actsRatioSum = selectedActsRatio.reduce((sum, price) => sum + price),
            sumActsTrunc = Helper.turnToDecimals(actsRatioSum);

        // transportco
        const selectedTransport = Majoration.TRANSPORTS[mileage.typeKey],
            transportSum = Transport.getTransportRatio(mileage, user),
            sumTransportTrunc = Helper.turnToDecimals(transportSum);

        // increase
        const selectedRatio = (selectedActs[0].type === "CCAM" ? Majoration.CCAM_RATIO : Majoration.NGAP_RATIO);

        const increaseResult = Transport.getIncreaseType(increase, selectedActs[0].type),
            ratioSelected = increaseResult[0],
            increaseSum = increaseResult[1],
            increaseSumTrunc = Helper.turnToDecimals(increaseSum);

        const totalSum = Helper.turnToDecimals(actsRatioSum + transportSum + increaseSum);


        return (
            <div className="content-body">
                <div className="container">

                    <div className="block-title is-flex-between">
                        <div>
                            <h1 className="title is-size-2-desktop is-inline">Consultation</h1>
                            <h2 className="title is-3 is-inline"> - Totaux</h2>
                        </div>
                        <div>
                            <button className="button is-outlined is-rounded is-primary has-mobile-margin"
                                    onClick={reset}
                            >
                                Réinitialiser
                            </button>
                        </div>
                    </div>

                    <article className="message is-warning">
                        <div className="message-body">

                            <div className="results-blocks">
                                <div className="results-blocks__column">
                                    <div className="results-blocks__column-header">

                                        <h3 className="subtitle is-4 has-text-centered">Actes sélectionnés</h3>
                                        <ol>
                                            {selectedActs.map((act, key) =>
                                                <li key={key}>
                                                    {Helper.getFirst10Words(act.name)} ({act.code})<br/>
                                                    Cotation :&nbsp;
                                                    <strong>{selectedActsPrices[key]} € </strong>
                                                    {selectedActsRatio[key] !== selectedActsPrices[key] &&
                                                    <span>
                                                         (prix appliqué :&nbsp;
                                                        {Math.floor(100 * selectedActsRatio[key]) / 100} €)
                                                    </span>
                                                    }
                                                </li>
                                            )}
                                        </ol>
                                    </div>
                                    <h4 className="title is-5 has-text-centered">
                                        <span className="heading is-metropole">
                                                {user.metropole ? "prix métropole" : "prix DOMTOM"}
                                        </span>
                                        Total :&nbsp;
                                        <span className="tag is-warning is-rounded is-medium">
                                                {sumActsTrunc} €
                                        </span>

                                    </h4>
                                </div>

                                <div className="results-blocks__column">
                                    <div className="results-blocks__column-header">
                                        <h3 className="subtitle is-4 has-text-centered">Transport</h3>
                                        {((mileage.typeKey < 3) &&
                                            <p>{mileage.distance} km en {selectedTransport.type}</p>) ||
                                        <p>{selectedTransport.type}</p>
                                        }
                                    </div>
                                    <h4 className="title is-5">Total :&nbsp;
                                        <span className="tag is-warning is-rounded is-medium">
                                            {sumTransportTrunc} €
                                        </span>
                                    </h4>
                                </div>

                                {increaseSum !== 0 &&
                                <div className="results-blocks__column">
                                    <div className="results-blocks__column-header">
                                        <h3 className="subtitle is-4 has-text-centered">Majoration</h3>
                                        <ol>
                                            {ratioSelected.map((ratioKey) =>
                                                <li key={ratioKey}>
                                                    {(selectedRatio[ratioKey]).type} <br/>
                                                    <strong>{selectedRatio[ratioKey].price} € </strong>
                                                </li>
                                            )}
                                        </ol>
                                    </div>
                                    <h4 className="title is-5">Total :&nbsp;
                                        <span className="tag is-warning is-rounded is-medium">
                                            {increaseSumTrunc} €
                                        </span>
                                    </h4>
                                </div>
                                }
                            </div>

                        </div>
                    </article>

                    <article className="message is-warning">
                        <div className="message-body text-center is-flex-center">
                            <div>
                                <h3 className="heading">Totaux cotations</h3>
                                <p className="title is-flex-center">
                                    {totalSum} €
                                </p>
                            </div>
                        </div>
                    </article>


                    <div className="tabs is-fullwidth">
                        <ul>
                            <li>
                                <button onClick={() => {setStep(step - 1)}}
                                        className="button is-rounded is-warning">
                                    <span className="icon">
                                        <i className="fas fa-angle-left" aria-hidden="true"/>
                                    </span>
                                    <span>Précédent</span>
                                </button>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        );
    }
;

export default Result;
